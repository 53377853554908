<template>
  <section class="section">
    <b-loading :is-full-page="false" v-model="loadingStoryDetails"></b-loading>

    <div v-if="selectedStory">
      <div class="columns mb-6">
        <div class="column is-9">
          <h4 class="title is-4">{{ selectedStory.title }}<br /><small>ID: {{ selectedStory.id }}</small></h4>

          <story-form :story="selectedStory"></story-form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import StoryForm from '../../components/Quests/StoryForm.vue';

export default {
  name: 'StoryDetails',
  components: {
    StoryForm,
  },
  props: {
    story: String,
  },
  computed: {
    ...mapState('quest', ['loadingStoryDetails', 'selectedStory']),
  },
  mounted() {
    this.loadStory(this.story);
  },
  beforeRouteUpdate(to, from, next) {
    this.loadStory(to.params.story);
    next();
  },
  methods: {
    loadStory(id) {
      this.$store.dispatch('quest/loadStoryDetails', id);
    },
  },
};
</script>

<style lang="scss">

</style>
